// Import Angular stuff

import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Observable, throwError } from 'rxjs';

import { LocalStorageService } from '@core/services/local-storage.service';

@Component({
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss'],
})
export class CookieDialogComponent  {

  constructor(private storageService: LocalStorageService,
    private dialog: MatDialogRef<CookieDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ok() {
    this.storageService.set('cookiesAccepted', new Date().toISOString())
    this.dialog.close();
  }
}
