// Import Angular stuff

import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

// Import RxJS stuff

import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';

// Import services used by the AuthenticationInterceptor

import { AuthenticationService } from '@core/services/authentication.service';
import { TokenService } from '@core/services/token.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  private isRefreshing = false;

  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private authenticationService: AuthenticationService;
  private tokenService: TokenService;

  /**
   *
   * @param injector We must use the injector in order to prevent a cyclic
   * dependency due to the fact that the UserService initiates a HTTP request
   * in its constructor.
   */

   constructor(private injector: Injector) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.tokenService === undefined) {
      this.tokenService = this.injector.get(TokenService);
    }

    let authorizedRequest: HttpRequest<any>;

    // Clone the request and replace the original headers with cloned headers, updated with the authorization.

    if (request.headers.has('Authorization')) {
      authorizedRequest = request.clone();
    } else {
      authorizedRequest = request.clone({
        setHeaders: { Authorization: 'Bearer ' + this.tokenService.getAccessToken() }
      });
    }

    // Send cloned request with header to the next handler and catch responses with a 400 or 401 status.

    return next.handle(authorizedRequest).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 400:
              return this.handle400(error);
            case 401:
              return this.handle401(request, next);
          }
        }

        return throwError(error);
      }));
  }

  private handle400(error: HttpErrorResponse) {
    if (this.authenticationService === undefined) {
      this.authenticationService = this.injector.get(AuthenticationService);
    }

    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      this.authenticationService.logout();
    }

    return throwError(error);
  }


  private handle401(request: HttpRequest<any>, next: HttpHandler) {
    if (this.authenticationService === undefined) {
      this.authenticationService = this.injector.get(AuthenticationService);
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authenticationService.refreshToken()
        .pipe(switchMap((newToken: string) => {
          if (newToken) {
            this.tokenSubject.next(newToken);
            return next.handle(request.clone({
              setHeaders: { Authorization: 'Bearer ' + this.tokenService.getAccessToken() }
            }));
          }

          // If we don't get a new token, we are in trouble so logout.
          this.authenticationService.logout();
          return throwError('DEF');
        }),
          catchError((error) => {
            // If there is an exception calling 'refreshToken', bad news so logout.

            const REFRESH_TIMEOUT_MESSAGE = 'You have been logged out due to inactivity.';
            this.authenticationService.logout();
            return throwError('ABC');
          }),
          finalize(() => {
            this.isRefreshing = false;
          }));
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(request.clone({
            setHeaders: { Authorization: 'Bearer ' + this.tokenService.getAccessToken() }
          }));
        }));
    }
  }
}
