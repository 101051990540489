// Import Angular stuff

import { Component } from '@angular/core';

// Import components and services used by the PrivacyPolicyComponent

import { WindowService} from '@core/services/window.service';

@Component({
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {

  constructor(public windowService: WindowService) {
  }
}
