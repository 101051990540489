// Import Angular stuff

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { UserService } from '@core/services/user.service';

import { RealmService } from '@core/services/realm.service';
import { LocalStorageService } from '@core/services/local-storage.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Input()
  public expanded: string;

  @Output()
  close: EventEmitter<void> = new EventEmitter<void>();

  public currentUser: any;

  public realms: any[];

  constructor(
    private userService: UserService,
    private realmService: RealmService,
    private localStorage: LocalStorageService
  ) {}

  public ngOnInit() {
    this.currentUser = this.userService.getCurrentUser();

    if (this.currentUser) {
      this.realmService.search().subscribe(realms => (this.realms = realms));
    }

    this.userService.userChange.subscribe(user => {
      this.currentUser = user;

      this.realmService.search().subscribe(realms => (this.realms = realms));
    });
  }

  public onClick() {
    this.close.emit();
  }

  public toggle(realm: any) {
    this.localStorage.set('realm', realm);
    this.expanded !== realm
      ? (this.expanded = realm)
      : (this.expanded = undefined);
  }
}
