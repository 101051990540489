// Import Angular stuff

import { Injectable } from '@angular/core';

// Import RxJS stuff

import { Observable } from 'rxjs';

// Import services used by the TokenService

import { LocalStorageService } from './local-storage.service';

const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';

@Injectable()
export class TokenService {
  constructor(private storageService: LocalStorageService) {}

  /**
   *
   *@returns {string}
   */

  public getAccessToken(): string {
    return this.storageService.get(ACCESS_TOKEN);
  }

  /**
   *
   * @param {string} token
   */

  public setAccessToken(token: string): void {
    this.storageService.set(ACCESS_TOKEN, token);
  }

  /**
   *
   *@returns {string}
   */

  public getRefreshToken(): string {
    return this.storageService.get(REFRESH_TOKEN);
  }

  /**
   *
   * @param {string} token
   */

  public setRefreshToken(token: string): void {
    this.storageService.set(REFRESH_TOKEN, token);
  }
}
