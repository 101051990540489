// Import Angular stuff

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { MatDialog } from '@angular/material';

// Import components and services used by the RootComponent

import { CookieDialogComponent } from '@shared/components/dialogs/cookie-dialog.component';

import { AlertService } from '@core/services/alert.service';
import { AuthenticationService } from '@core/services/authentication.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { TokenService } from '@core/services/token.service';

const TITLE = 'Platform Administration';

const REFRESH_TIMEOUT_MESSAGE = 'You have been logged out due to inactivity.';

@Component({
  selector: 'body',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  constructor(public titleService: Title,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private tokenService: TokenService,
    private alertService: AlertService,
    private storageService: LocalStorageService) {
    titleService.setTitle(TITLE);
  }

  public ngOnInit() {
    if (this.tokenService.getRefreshToken()) {
      this.authenticationService.refreshToken().subscribe(this.onRefreshSuccess, this.onRefreshFailure);
    }

    // If use of cookies has not been accepted, show a dialog "forcing" the user to accept in order to proceed.

    if (!this.storageService.get('cookiesAccepted'))
      this.dialog.open(CookieDialogComponent, { disableClose: true });
  }

  private onRefreshSuccess = () => {
  }

  private onRefreshFailure = (error) => {
    if (error.status === 401 && error.error.error === 'invalid_token') {
      this.alertService.warning(REFRESH_TIMEOUT_MESSAGE, undefined, 3000);
    }
  }
}
