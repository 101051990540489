// Import Angular stuff

import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { ReactiveFormsModule } from '@angular/forms';

// Import flex layout and CDK

import { FlexLayoutModule } from '@angular/flex-layout';

import { CdkTableModule } from '@angular/cdk/table';

// Import Angular Material stuff

import {
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule
} from '@angular/material';

// Import pipes, services, components and directives

import { KeyPipe } from './pipes/key.pipe';
import { CreatedPipe } from './pipes/created.pipe';
import { UpdatedPipe } from './pipes/updated.pipe';

import { CookieDialogComponent } from './components/dialogs/cookie-dialog.component';
import { LoginDialogComponent } from './components/dialogs/login-dialog.component';

import { PrivacyPolicyComponent } from './components/privacy-policy.component';
import { TermsOfUseComponent } from './components/terms-of-use.component';
import { NotFoundComponent } from './components/not-found.component';

import { AuthorizationService } from './services/authorization.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpModule,
    ReactiveFormsModule,
    CdkTableModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    FlexLayoutModule
  ],
  declarations: [
    KeyPipe,
    CreatedPipe,
    UpdatedPipe,
    CookieDialogComponent,
    LoginDialogComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    NotFoundComponent
  ],
  providers: [
    AuthorizationService
  ],
  entryComponents: [CookieDialogComponent, LoginDialogComponent],
  exports: [
    CommonModule,
    RouterModule,
    HttpModule,
    ReactiveFormsModule,
    CdkTableModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    FlexLayoutModule,
    KeyPipe,
    CreatedPipe,
    UpdatedPipe,
    CookieDialogComponent,
    LoginDialogComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    NotFoundComponent
  ]
})
export class SharedModule {}
