// Import Angular stuff

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationService {
  private readonly PATH = '/api/authorizations';

  constructor(private httpClient: HttpClient) { }

  public searchByUser(key: string): Observable<any[]> {
    return this.httpClient.get<any[]>(this.PATH + '?user=' + key);
  }

  public searchByRole(key: string): Observable<any[]> {
    return this.httpClient.get<any[]>(this.PATH + '?role=' + key);
  }


  public post(payload: any): Observable<any[]> {
    return this.httpClient.post<any[]>(this.PATH, payload);
  }
}
