// Import Angular stuff

import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Import pipes, services, interceptors, components and directives

import { AlertService } from './services/alert.service';
import { AuthService, AuthServiceConfig } from './services/auth.service';
import { AuthenticationService } from './services/authentication.service';
import { LocalStorageService } from './services/local-storage.service';
import { TokenService } from './services/token.service';
import { UserService } from './services/user.service';
import { WindowService } from './services/window.service';

import { RealmService } from './services/realm.service';

import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

import { RealmInterceptor } from './interceptors/realm.interceptor';

import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  LinkedInLoginProvider
} from './providers/login';

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('11339069686-v64gsia2q1iip6ka996gehkg1ala2g2t.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('277688382736354')
  },
  {
    id: LinkedInLoginProvider.PROVIDER_ID,
    provider: new LinkedInLoginProvider('LinkedIn-client-Id', false, 'en_US')
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    AlertService,
    AuthenticationService,
    LocalStorageService,
    TokenService,
    UserService,
    WindowService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RealmInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    RealmService,
    AuthService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ]
})
export class CoreModule {
  /**
   * Prevent feature modules from reimporting CoreModule. CoreModule should only
   * be imported once in the RootModule.
   *
   * @see https://angular.io/docs/ts/latest/guide/ngmodule.html#prevent-reimport
   * @param parentModule
   */

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the RootModule only!'
      );
    }
  }
}
