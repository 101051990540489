// Import Angular stuff

import { Injectable } from '@angular/core';

// Import RxJS stuff

import { Subject } from 'rxjs';

// Import services used by the UserService

import { LocalStorageService } from './local-storage.service';

const USER_KEY = 'currentUser';

@Injectable()
export class UserService {
  public userChange: Subject<any> = new Subject<any>();

  constructor(private storageService: LocalStorageService) {}

  public getCurrentUser(): any {
    return this.storageService.get(USER_KEY);
  }

  public setCurrentUser(user: any): void {
    if (user !== this.getCurrentUser()) {
      this.userChange.next(user);
    }

    this.storageService.set(USER_KEY, user);
  }
}
