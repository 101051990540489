// Import Angular stuff

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

// Import RxJS stuff

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Import services used by the ErrorInterceptor

import { AlertService } from '@core/services/alert.service';

const GENERAL_SERVER_ERROR = 'General server error';
const CONNECTION_ERROR = 'Connection error';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private alertService: AlertService) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler) {

    return next.handle(request).pipe(
      catchError((error, caught) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 0) {
            this.alertService.error(CONNECTION_ERROR, 'OK');
          } else if (error.status === 500) {
            this.alertService.error(GENERAL_SERVER_ERROR, 'OK');
          }
        }

        return throwError(error);
      }));
  }
}
