import { BaseLoginProvider } from './base-login-provider';

declare let IN: any;

export class LinkedInLoginProvider extends BaseLoginProvider {

    public static readonly PROVIDER_ID: string = 'LINKEDIN';

    constructor(
        private clientId: string,
        private authorize?: boolean,
        private lang?: string,
        private fields: string = 'id,first-name,last-name,email-address,picture-url'
    ) {
        super();
    }

    public initialize(): Promise<void> {
        let inner_text = '';

        inner_text += 'api_key: ' + this.clientId + '\r\n';
        inner_text += 'authorize:' + (this.authorize ? 'true' : 'false') + '\r\n';
        inner_text += 'lang: ' + (this.lang ? this.lang : 'en_US') + '\r\n';

        return new Promise((resolve, reject) => {
            this.loadScript(LinkedInLoginProvider.PROVIDER_ID,
                '//platform.linkedin.com/in.js',
                () => {
                    const that = this;
                    setTimeout(() => {
                        this._readyState.next(true);
                        resolve();
                    }, 800);
                }, false, inner_text);
        });
    }

    public getStatus(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.onReady().then(() => {
                if (IN.User.isAuthorized()) {
                    IN.API.Raw(`/people/~:(${this.fields})`).result((res: any) => {
                        const user: any = {};

                        user.id = res.id;
                        user.name = res.firstName + ' ' + res.lastName;
                        user.email = res.emailAddress;
                        user.photoUrl = res.pictureUrl;
                        user.firstName = res.firstName;
                        user.lastName = res.lastName;
                        user.authToken = IN.ENV.auth.oauth_token;
                        user.linkedIn = res;

                        resolve(user);
                    });
                }
            });
        });
    }

    public login(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.onReady().then(() => {
                IN.User.authorize(() => {
                    IN.API.Raw(`/people/~:(${this.fields})`).result((res: any) => {
                        const user: any = {};

                        user.id = res.id;
                        user.name = res.firstName + ' ' + res.lastName;
                        user.email = res.emailAddress;
                        user.photoUrl = res.pictureUrl;
                        user.firstName = res.firstName;
                        user.lastName = res.lastName;
                        user.authToken = IN.ENV.auth.oauth_token;

                        user.linkedIn = res;

                        resolve(user);
                    });
                });
            });
        });
    }

    public logout(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.onReady().then(() => {
                IN.User.logout(() => {
                    resolve();
                }, {});
            });
        });
    }
}
