// Import Angular stuff

import { Component, ViewChild, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';

import { MatSidenav, MatToolbar } from '@angular/material';

import { MediaChange, ObservableMedia } from '@angular/flex-layout';

import { Subscription } from 'rxjs';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements AfterViewChecked, OnDestroy {
  @ViewChild('sidenav')
  public sidenav: MatSidenav;
  @ViewChild('toolbar')
  public toolbar: MatToolbar;

  watcher: Subscription;
  activeMediaQuery: string;

  constructor(public media: ObservableMedia) {
    this.watcher = media.subscribe((change: MediaChange) => {

      this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';

      if (change.mqAlias === 'xs' || change.mqAlias === 'sm') {
        if (this.sidenav) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        }
      } else {
        if (this.sidenav) {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      }
    });
  }

  public ngAfterViewChecked(): void {
    this.media.isActive('lt-md') ? this.sidenav.mode = 'over' : this.sidenav.mode = 'side';
  }

  public ngOnDestroy(): void {
    this.watcher.unsubscribe();
  }

  public toggleSidenav(): void {
    if (this.media.isActive('lt-md')) {
      this.sidenav.toggle();
    }
  }
}
