// Import Angular stuff

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { MatDialog } from '@angular/material';

// Import components and services used by the ToolbarComponent

import { LoginDialogComponent } from '@shared/components/dialogs/login-dialog.component';

import { AuthenticationService } from '@core/services/authentication.service';
import { UserService } from '@core/services/user.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Input()
  public showMenuToggle: boolean;

  @Output()
  public sidenavToggle: EventEmitter<void> = new EventEmitter<void>();

  public currentUser: any;

  constructor(
    public titleService: Title,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private userService: UserService) {
  }

  public ngOnInit() {
    this.currentUser = this.userService.getCurrentUser();

    this.userService.userChange.subscribe(user => {
      this.currentUser = user;
    });
  }

  public login() {
    const dialogRef = this.dialog.open(LoginDialogComponent, { disableClose: true });
  }

  public logout() {
    this.authenticationService.logout();
  }
}
