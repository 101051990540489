// Import Angular stuff

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';

// Import RxJS stuff

import { Observable, Subject, throwError } from 'rxjs';

import { map, catchError } from 'rxjs/operators';

// Import services used by the AuthenticationService

import { AlertService } from './alert.service';
import { UserService } from './user.service';
import { TokenService } from './token.service';

const TOKEN_PATH = '/api/authorization/tokens';

const LOGIN_SUCCESS_MESSAGE = 'You have successfully been logged in as ';
const LOGIN_FAILURE_MESSAGE =
  'An error occurred while logging in. Please try again later.';
const LOGOUT_SUCCESS_MESSAGE = 'You have been logged out.';
const LOGOUT_FAILURE_MESSAGE = 'An error occurred while logging out.';
const REFRESH_FAILURE_MESSAGE =
  'An error occurred while refreshing your access token.';

const options: any = {
  headers: new HttpHeaders({
    Authorization: 'Basic ' + btoa('API' + ':' + 'secret'),
    'Content-Type': 'application/x-www-form-urlencoded'
  })
};

@Injectable()
export class AuthenticationService {
  private loginSource = new Subject<any>();
  private logoutSource = new Subject<void>();

  private cachedRequests: Array<HttpRequest<any>> = [];

  constructor(
    private alertService: AlertService,
    private userService: UserService,
    private tokenService: TokenService,
    private httpClient: HttpClient
  ) {}

  public onLogin = this.loginSource.asObservable();

  public onLogout = this.logoutSource.asObservable();

  public login(username: string, password: string): Observable<any> {
    return this.httpClient
      .post(
        TOKEN_PATH,
        'grant_type=password&username=' + username + '&password=' + password,
        options
      )
      .pipe(
        map(this.onLoginSuccess),
        catchError(this.onLoginFailure)
      );
  }

  public logout() {
    this.httpClient
      .delete(
        '/api/authorization/tokens/' +
          this.tokenService.getAccessToken()
      )
      .subscribe(this.onLogoutSuccess, this.onLogoutFailure);
  }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  public refreshToken() {
    return this.httpClient
      .post(
        TOKEN_PATH,
        'grant_type=refresh_token&refresh_token=' +
          this.tokenService.getRefreshToken(),
        options
      )
      .pipe(
        map(this.onRefreshSuccess),
        catchError(this.onRefreshFailure)
      );
  }

  private onLoginSuccess = (response): any => {
    this.tokenService.setAccessToken(response.access_token);
    this.tokenService.setRefreshToken(response.refresh_token);

    this.httpClient.get('/api/users/me').subscribe((me: any) => {
      this.userService.setCurrentUser(me);
      this.loginSource.next();

      this.alertService.success(
        LOGIN_SUCCESS_MESSAGE + me.username + '.',
        undefined,
        3000
      );

      return me;
    });
  }

  private onLoginFailure = (error): Observable<any> => {
    this.tokenService.setAccessToken(undefined);
    this.tokenService.setRefreshToken(undefined);

    this.userService.setCurrentUser(undefined);

    if (error instanceof HttpErrorResponse && error.status !== 400) {
      this.alertService.error(LOGIN_FAILURE_MESSAGE, undefined);
    }

    return throwError(error);
  }

  private onLogoutSuccess = (response): any => {
    this.tokenService.setAccessToken(undefined);
    this.tokenService.setRefreshToken(undefined);

    this.userService.setCurrentUser(undefined);

    this.alertService.success(LOGOUT_SUCCESS_MESSAGE, undefined, 3000);
  }

  private onLogoutFailure = (error): Observable<any> => {
    this.tokenService.setAccessToken(undefined);
    this.tokenService.setRefreshToken(undefined);

    this.userService.setCurrentUser(undefined);

    this.alertService.error(LOGOUT_FAILURE_MESSAGE, undefined);

    return throwError(error);
  }

  private onRefreshSuccess = (response): Observable<any> => {
    this.tokenService.setAccessToken(response.access_token);
    this.tokenService.setRefreshToken(response.refresh_token);

    return response;
  }

  private onRefreshFailure = (error): Observable<any> => {
    this.tokenService.setAccessToken(undefined);
    this.tokenService.setRefreshToken(undefined);

    this.userService.setCurrentUser(undefined);

    this.alertService.error(REFRESH_FAILURE_MESSAGE, undefined);

    return throwError(error);
  }
}
