// Import Angular stuff

import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {
  public getHostname(): string {
    return window.location.hostname;
  }
}
