// Import Angular stuff

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';

// Import services used by the RealmInterceptor

import { LocalStorageService } from '@core/services/local-storage.service';

@Injectable()
export class RealmInterceptor implements HttpInterceptor {
  constructor(private localStorage: LocalStorageService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler) {
    let modifiedRequest: HttpRequest<any>;

    // Clone the request and add an 'X-Realm' header.

    if (!request.url.startsWith('/api/realms') && this.localStorage.get('realm')) {
      modifiedRequest = request.clone({
        setHeaders: { 'X-Realm': this.localStorage.get('realm').key }
      });
    } else {
      modifiedRequest = request.clone();
    }

    // Send modifed request with header to the next handler.

    return next.handle(modifiedRequest);
  }
}
