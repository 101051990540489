// Import Angular stuff

import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  public get(key: string): any {
    return JSON.parse(window.localStorage.getItem(key));
  }

  public set(key: string, value: any): void {
    // Remove the item from the browser's local storage if value is null or
    // undefined.

    if (!value) {
      window.localStorage.removeItem(key);
      return;
    }

    window.localStorage.setItem(key, JSON.stringify(value));
  }
}
