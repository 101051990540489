// Import Angular stuff

import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Observable, throwError } from 'rxjs';

import { AuthenticationService } from '@core/services/authentication.service';
import { AlertService } from '@core/services/alert.service';

import { AuthService } from '@core/services/auth.service';
import { GoogleLoginProvider, FacebookLoginProvider, LinkedInLoginProvider } from '@core/providers/login';


const LOGIN_SUCCESS_MESSAGE = 'You have been logged in as ';
const BAD_CREDENTIALS_MESSAGE = 'Login failed, please try again';
const GENERAL_ERROR_MESSAGE = 'An error occurred while logining in. Please try again later.';

@Component({
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnInit {

  public form: FormGroup;

  public username = new FormControl('', Validators.required);
  public password = new FormControl('', Validators.required);

  public message: String;

  constructor(private authenticationService: AuthenticationService,
    private authService: AuthService,
    private alertService: AlertService,
    private builder: FormBuilder,
    private dialog: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit() {
    this.form = this.builder.group({
      'username': this.username,
      'password': this.password
    });
  }

  public login() {
    this.message = undefined;

    this.authenticationService.login(this.username.value, this.password.value)
      .subscribe(this.onLoginSuccess, this.onLoginFailure);
  }

  public cancel() {
    this.dialog.close();
  }

  public loginWithGoogle(): void {
    this.dialog.close();
    this.authService.login(GoogleLoginProvider.PROVIDER_ID).then(this.onLoginSuccess);
  }

  public loginWithFacebook(): void {

    this.authService.login(FacebookLoginProvider.PROVIDER_ID).then((response) => {
      this.message = response.name;
    });
  }

  public loginWithLinkedin(): void {
    this.dialog.close();
    this.authService.login(LinkedInLoginProvider.PROVIDER_ID).then(this.onLoginSuccess);
  }

  private onLoginSuccess = (response) => {
    this.dialog.close();
  }

  private onLoginFailure = (error): Observable<any> => {
    if (error.status === 400) {
      this.message = BAD_CREDENTIALS_MESSAGE;
    } else {
      // Close the login dialog. Error messages has been displayed by the authentication service.

      this.dialog.close();
    }

    return throwError(error);
  }
}
