import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';

/**
 *
 */

@Injectable()
export class AlertService {
  constructor(private snackBar: MatSnackBar) {}

  /**
   *
   * @param message
   * @param button
   * @param timeout
   * @returns MatSnackBarRef<SimpleSnackBar>
   */

  public success(
    message: string,
    acknowledge?: string,
    timeout?: number
  ): MatSnackBarRef<SimpleSnackBar> {
    const config: any = { panelClass: 'success' };

    config.duration = timeout ? timeout : undefined;

    return this.snackBar.open(
      message,
      acknowledge
        ? acknowledge
        : acknowledge
          ? acknowledge
          : timeout
            ? ''
            : 'OK',
      config
    );
  }

  /**
   *
   * @param message
   * @param button
   * @param timeout
   * @returns MatSnackBarRef<SimpleSnackBar>
   */

  public info(
    message: string,
    acknowledge?: string,
    timeout?: number
  ): MatSnackBarRef<SimpleSnackBar> {
    const config: any = { panelClass: 'info' };

    config.duration = timeout ? timeout : undefined;

    return this.snackBar.open(
      message,
      acknowledge
        ? acknowledge
        : acknowledge
          ? acknowledge
          : timeout
            ? ''
            : 'OK',
      config
    );
  }

  /**
   *
   * @param message
   * @param button
   * @param timeout
   * @returns MatSnackBarRef<SimpleSnackBar>
   */

  public warning(
    message: string,
    acknowledge?: string,
    timeout?: number
  ): MatSnackBarRef<SimpleSnackBar> {
    const config: any = { panelClass: 'warning' };

    config.duration = timeout ? timeout : undefined;

    return this.snackBar.open(
      message,
      acknowledge
        ? acknowledge
        : acknowledge
          ? acknowledge
          : timeout
            ? ''
            : 'OK',
      config
    );
  }

  /**
   *
   * @param message
   * @param button
   * @param timeout
   * @returns MatSnackBarRef<SimpleSnackBar>
   */

  public error(
    message: string,
    acknowledge?: string,
    timeout?: number
  ): MatSnackBarRef<SimpleSnackBar> {
    const config: any = { panelClass: 'error' };

    config.duration = timeout ? timeout : undefined;

    return this.snackBar.open(
      message,
      acknowledge
        ? acknowledge
        : acknowledge
          ? acknowledge
          : timeout
            ? ''
            : 'OK',
      config
    );
  }
}
