import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layout.component';

import { HomeComponent } from './home.component';

import { PrivacyPolicyComponent } from '@shared/components/privacy-policy.component';
import { TermsOfUseComponent } from '@shared/components/terms-of-use.component';
import { NotFoundComponent } from '@shared/components/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'index.html',
        component: HomeComponent
      },
      {
        path: 'realms',
        loadChildren: './features/realms/realms.module#RealmsModule'
      },
      {
        path: 'clients',
        loadChildren: './features/clients/clients.module#ClientsModule'
      },
      {
        path: 'scopes',
        loadChildren: './features/scopes/scopes.module#ScopesModule'
      },
      {
        path: 'roles',
        loadChildren: './features/roles/roles.module#RolesModule'
      },
      {
        path: 'accounts',
        loadChildren: './features/accounts/accounts.module#AccountsModule'
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'terms-of-use',
        component: TermsOfUseComponent
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class RootRouting { }
