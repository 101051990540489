// Import Angular stuff

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';

// Import core, application wide functionality

import { CoreModule } from '@core/core.module';

// Import shared functionality, including Flex Layout

import { SharedModule } from '@shared/shared.module';

// Import root routing definitions

import { RootRouting } from './root.routing';

// Import components which are eagerly loaded by the app

import { RootComponent } from './root.component';
import { LayoutComponent } from './layout.component';
import { ToolbarComponent } from './toolbar.component';
import { SidenavComponent } from './sidenav.component';
import { HomeComponent } from './home.component';

@NgModule({

  imports: [
    BrowserModule,
    environment.production ? ServiceWorkerModule.register('/ngsw-worker.js') : [],
    CoreModule,
    SharedModule,
    RootRouting,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [
    RootComponent,
    LayoutComponent,
    ToolbarComponent,
    SidenavComponent,
    HomeComponent
  ],
  providers: [
  ],
  bootstrap: [RootComponent]
})
export class RootModule { }
